import { createGlobalStyle } from "styled-components"

import waves from "../../images/waves.svg"

const developer = 'Joseph Corrado'

const GlobalStyles = createGlobalStyle`
  /* Josh Comeau's global styles */
  /* 1. Use a more-intuitive box-sizing model. */
  *, *::before, *::after {
    box-sizing: border-box;
  }
  /* 2. Remove default margin for common elements */
  body, h1, h2, h3, h4, h5, h6, p, figure, blockquote, ul, ol, dl, dt, dd {
    margin: 0;
  }
  /* 3. Allow percentage-based heights in the application */
  html, body {
    height: 100%
  }
  /* 4. Improve the typography across the site. */
  body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
  }
  /* 5. Make images easier to work with */
  img,
  picture {
    max-width: 100%;
    display: block;
  }
  /* 6. Inherit fonts for inputs and buttons */
  input, button, textarea, select {
    font: inherit;
  }
  /* 7. Create a root stacking context */
  #__next {
    isolation: isolate;
  }

  /* site-specific global stylings */
  :root {
    --DEVELOPER: ${developer};
    --NASA: ${4/16}rem;
    --CLAMP: clamp(4em, -1em + 3vh + 8vw, 6em);

    --DEG: 35deg;
    --ORANGE: hsl(var(--DEG) 100% 50%); 
    --EBONY: hsl(var(--DEG) 0% 10%);
    --CHARCOAL: hsl(var(--DEG) 0% 30%);
    --IVORY: hsl(var(--DEG) 0% 98%);
    --ASHEN: hsl(var(--DEG) 10% 78%);
    --TAN: hsl(var(--DEG) 50% 65%);

    --GOLDEN: hsl(45deg 100% 50%); 
    --SKY: hsl(196deg 100% 70%);
    --MAROON: hsl(3deg 100% 21%);

    --SHADOW: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
      6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
      12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
      22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
      41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
      100px 100px 80px rgba(0, 0, 0, 0.07);
    --SHADOW-FULL: 0.8px 0.8px 2.2px rgba(0, 0, 0, 0.07),
      2px 2px 5.3px rgba(0, 0, 0, 0.05),
      3.8px 3.8px 10px rgba(0, 0, 0, 0.042),
      6.7px 6.7px 17.9px rgba(0, 0, 0, 0.035),
      12.5px 12.5px 33.4px rgba(0, 0, 0, 0.028),
      30px 30px 80px rgba(0, 0, 0, 0.02);
  }
  /* auto overflow and hero image */
  body {
    overflow-y: scroll;
    background: no-repeat url(${waves});
    background-size: cover;
    background-color: var(--SKY);
    font-size: 1.1rem;
  }
  /* button reset */
  button {
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
    cursor: pointer;
    color: black;
  }
`

export default GlobalStyles